import {
  CLEANLINESS, CONTRACT_TYPE_CATEGORY,
  DamageZones,
  DOCUMENT_STATUS,
  DOWNLOADABLE_DOCUMENT,
  GENERAL_CONDITION,
  RECEPTION_TYPE,
  RESTITUTION_STATUS, TAKEOVER_TYPE,
  TIRE_SEASON,
} from "types/enums.types"

const common = {
  "application.title": "L'application Mobilize Check est disponible au téléchargement sur\u00a0:",
  "button.search.label": "Rechercher",
  "button.reset": "Réinitialiser",
  "search.results": "{number} restitutions trouvées",
  "search.button": "Rechercher",
  "search.placeholder": "Rechercher une immatriculation, un nom de client, une adresse email",
  "button.toggle.all": "Tous",
  "button.back": "Retour",
  "button.filter": "Filtrer",
  "button.validate": "Valider",
  "button.export": "exporter",
  "button.ok": "OK",
  "button.download": "Télécharger",
  recaptcha: "Ce site est protégé par reCAPTCHA et les {privacy} et {terms} de Google s'appliquent",
  "recaptcha.privacy": "règles de confidentialité",
  "recaptcha.terms": "conditions d’utilisation",
  "screen.authentication.usb": "Je m'authentifie avec ma clé token",
  "screen.authentication.usb.submit": "Connexion avec token",
  "screen.authentication.okta": "Je m'authentifie avec OKTA",
  "screen.authentication.okta.submit": "Connexion avec OKTA",
  "screen.authentication.login": "Je m'authentifie avec mes identifiants",
  "screen.authentication.title": "Bienvenue sur la plateforme Admin Mobilize check",
  "screen.details.vehicle": "Véhicule",
  "screen.details.restitution": "Restitution",
  "screen.details.damages": "Relevé de dommage - {price}",
  "screen.details.documents": "Télécharger document",
  [`screen.details.${DOWNLOADABLE_DOCUMENT.PV_LINK}`]: "PV de restitution",
  [`screen.details.${DOWNLOADABLE_DOCUMENT.RECEIPT_LINK}`]: "Bon de restitution",
  "screen.details.preClose": "Pré-cloturer le contrat",
  "screen.details.mechanicalDamages": "Problème(s) mécanique(s)",
  "screen.details.amovibles": "Amovibles",
  [`screen.details.amovibles.${DOCUMENT_STATUS.ABSENT}`]: "Absent",
  [`screen.details.amovibles.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Non remis à la livraison",
  [`screen.details.amovibles.${DOCUMENT_STATUS.PRESENT}`]: "Présent",
  "screen.password.reset.title": "Mot de passe oublié",
  "screen.password.validation.title": "Activer mon compte",
  "screen.password.rule": "Le mot de passe doit contenir",
  "screen.password.rule.bold": "au moins 8 caractères, une minuscule, une majuscule, un chiffre et un caractère spécial parmis €@$!%*?& !",
  "screen.password.input.password.label": "Mot de passe",
  "screen.password.input.confirmation.label": "Confirmer le mot de passe",
  "screen.password.success": "Votre mot de passe a bien été enregistré !",
  "screen.signUp.success": "Votre validation a bien été prise en compte !",
  "screen.signUp.error": "Une erreur est survenue lors de la validation du compte !",
  "tooltip.duplicateKeys.true": "Double des clés remise à l'expert",
  "tooltip.duplicateKeys.false": "Double des clés manquant",
  "tooltip.noSignatory.true": "Signataire absent",
  "tooltip.signatoryRefused.true": "Refus de signer par le client",
  "tooltip.noSignatory.false": "Signature du client",
  "tooltip.noSignatory.undefined": "-",
  "tooltip.notMoving.true": "Véhicule non roulant",
  "tooltip.notMoving.false": "Véhicule roulant",
  "tooltip.notMoving.undefined": "-",
  "tooltip.massRetail": "Véhicule grande distribution",
  [`tooltip.duplicateKeys.${DOCUMENT_STATUS.PRESENT}`]: "Double des clés présent",
  [`tooltip.duplicateKeys.${DOCUMENT_STATUS.ABSENT}`]: "Double des clés absent",
  [`tooltip.duplicateKeys.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Double des clés non remis à la livraison",
  [`tooltip.duplicateKeys.undefined`]: "-",
  [`tooltip.registrationCard.${DOCUMENT_STATUS.PRESENT}`]: "Carte grise présente",
  [`tooltip.registrationCard.${DOCUMENT_STATUS.ABSENT}`]: "Carte grise absente",
  [`tooltip.registrationCard.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Carte grise non remise à la livraison",
  [`tooltip.registrationCard.undefined`]: "-",
  "filter.menu.title": "Filtrer",
  "header.menu.logout": "Me déconnecter",
  "filter.contractType": "Type de contrat",
  "filter.contractTypeCategory": "Catégorie de contrat",
  [`filter.contractTypeCategory.${CONTRACT_TYPE_CATEGORY.DC}`]: "MFS",
  [`filter.contractTypeCategory.${CONTRACT_TYPE_CATEGORY.DL}`]: "ML&C",
  "filter.takeoverType": "Type de reprise",
  [`filter.takeoverType.${TAKEOVER_TYPE.CONSTRUCTOR}`]: "Reprise constructeur",
  [`filter.takeoverType.${TAKEOVER_TYPE.SUPPLIER}`]: "Reprise fournisseur",
  "filter.mileage": "Kilométrage",
  "filter.model": "Modèle",
  "filter.date": "Date de restitution",
  "filter.mechanicalProblem": "Problème mecanique",
  "filter.receptionist": "Réceptionnaire",
  "filter.receptionType": "Type de restitution",
  [`filter.receptionType.${RECEPTION_TYPE.RECEPTION}`]: "Réception",
  [`filter.receptionType.${RECEPTION_TYPE.EXPERTISE}`]: "Expertise",
  "filter.receptionPlace": "Lieu de la restitution",
  "filter.registrationCard": "Carte grise",
  "filter.receptionType.EXPERTISE": "Expertise",
  "filter.noSignatory": "Signataire présent",
  "filter.noSignatory.true": "Non",
  "filter.noSignatory.false": "Oui",
  "filter.notMoving": "Non roulant",
  "filter.notMoving.true": "Oui",
  "filter.notMoving.false": "Non",
  "filter.registrationCard.true": "Remise à l'expert",
  "filter.registrationCard.false": "Non remise à l'expert",
  [`filter.registrationCard.${DOCUMENT_STATUS.PRESENT}`]: "Présente",
  [`filter.registrationCard.${DOCUMENT_STATUS.ABSENT}`]: "Absente",
  [`filter.registrationCard.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Non remise à la livraison",
  "filter.duplicateKeys": "Double des clés",
  [`filter.duplicateKeys.${DOCUMENT_STATUS.PRESENT}`]: "Présent",
  [`filter.duplicateKeys.${DOCUMENT_STATUS.ABSENT}`]: "Absent",
  [`filter.duplicateKeys.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Non remis à la livraison",
  "filter.status": "Statut",
  [`filter.status.${RESTITUTION_STATUS.TODO}`]: "À faire",
  [`filter.status.${RESTITUTION_STATUS.TO_COMPLETE}`]: "Pré-clôturé, en attente d’expertise",
  [`filter.status.${RESTITUTION_STATUS.IN_PROGRESS}`]: "En cours",
  [`filter.status.${RESTITUTION_STATUS.WAITING_VALIDATION}`]: "En attente de validation",
  [`filter.status.${RESTITUTION_STATUS.VALIDATED}`]: "Validée",
  [`filter.status.${RESTITUTION_STATUS.PRE_CLOSED}`]: "Pré-cloturée",
  [`filter.status.${RESTITUTION_STATUS.DONE}`]: "Terminée",
  [`filter.status.${RESTITUTION_STATUS.ERROR}`]: "Erreur",
  [`filter.status.${RESTITUTION_STATUS.CANCELLED}`]: "Annulée",
  "filter.tenantName": "Nom du locataire",
  "restitution.registrationCard": "Carte grise",
  "restitution.airCompressor": "Compresseur à air",
  "restitution.expertComment": "Commentaire de l'expert",
  "restitution.tools": "Outils",
  "restitution.antenna": "Antenne",
  "restitution.luggageCover": "Cache bagage",
  "restitution.noSignatory.true": "Refus de signer par le client",
  "restitution.noSignatory.false": "Signature du client",
  "restitution.notMoving.true": "Véhicule non roulant",
  "restitution.notMoving.false": "Véhicule roulant",
  "restitution.massRetail": "Véhicule grande distribution",
  [`restitution.registrationCard.${DOCUMENT_STATUS.PRESENT}`]: "Présente",
  [`restitution.registrationCard.${DOCUMENT_STATUS.ABSENT}`]: "Absente",
  [`restitution.registrationCard.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Non remise à la livraison",
  [`restitution.registrationCard.undefined`]: "-",
  "restitution.duplicateKeys": "Double des clés",
  [`restitution.duplicateKeys.${DOCUMENT_STATUS.PRESENT}`]: "Présent",
  [`restitution.duplicateKeys.${DOCUMENT_STATUS.ABSENT}`]: "Absent",
  [`restitution.duplicateKeys.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Non remis à la livraison",
  [`restitution.duplicateKeys.undefined`]: "-",
  "restitution.userManual": "Manuel d'utilisation",
  [`restitution.userManual.${DOCUMENT_STATUS.PRESENT}`]: "Présent",
  [`restitution.userManual.${DOCUMENT_STATUS.ABSENT}`]: "Absent",
  [`restitution.userManual.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Non remis à la livraison",
  "restitution.domesticChargingCable": "Cable de charge domestique",
  [`restitution.domesticChargingCable.${DOCUMENT_STATUS.PRESENT}`]: "Présent",
  [`restitution.domesticChargingCable.${DOCUMENT_STATUS.ABSENT}`]: "Absent",
  [`restitution.domesticChargingCable.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Non remis à la livraison",
  "restitution.fastChargingCable": "Cable de charge rapide",
  [`restitution.fastChargingCable.${DOCUMENT_STATUS.PRESENT}`]: "Présent",
  [`restitution.fastChargingCable.${DOCUMENT_STATUS.ABSENT}`]: "Non remis à la livraison",
  [`restitution.fastChargingCable.${DOCUMENT_STATUS.NOT_DELIVERED}`]: "Absent",
  "restitution.cleanlinessInt": "Propreté intérieure",
  "restitution.cleanlinessExt": "Propreté extérieure",
  [`restitution.cleanliness.${CLEANLINESS.TODO}`]: "À faire",
  [`restitution.cleanliness.${CLEANLINESS.CLEAN}`]: "Propre",
  "restitution.damages.operation": "Operation",
  "restitution.damages.partPrice": "Prix pièce",
  "restitution.damages.estimatedPrice": "Montant évalué",
  "restitution.damages.bodyDuration": "Temps carrosserie",
  "restitution.damages.damage": "Dommage",
  "restitution.damages.element": "Elément",
  "restitution.mechanicalDamages.element": "Elément",
  "restitution.mechanicalDamages.comment": "Commentaire",
  "restitution.damages.obsolescence": "Vétusté",
  "restitution.damages.position": "Position",
  "restitution.damages.paintDuration": "Temps peinture",
  "restitution.damages.photos": "Photos",
  "restitution.damages.severity": "Gravité",
  "restitution.damages.totalPrice": "Montant après vétusté",
  [`zone.${DamageZones.LEFT_REAR_WHEEL}`]: "Roue arrière gauche",
  [`zone.${DamageZones.LEFT_REAR_FENDER}`]: "Aile arrière gauche",
  [`zone.${DamageZones.LEFT_REAR_DOOR}`]: "Portière arrière gauche",
  [`zone.${DamageZones.LEFT_REAR_WINDOW}`]: "Vitre arrière gauche",
  [`zone.${DamageZones.LEFT_FRONT_WINDOW}`]: "Vitre avant gauche",
  [`zone.${DamageZones.LEFT_FRONT_DOOR}`]: "Portière avant gauche",
  [`zone.${DamageZones.LEFT_SIDE_SILL}`]: "Bas de caisse gauche",
  [`zone.${DamageZones.LEFT_FRONT_FENDER}`]: "Aile avant gauche",
  [`zone.${DamageZones.LEFT_FRONT_WHEEL}`]: "Roue avant gauche",
  [`zone.${DamageZones.LEFT_REAR_VIEW_MIRROR}`]: "Rétroviseur gauche",
  [`zone.${DamageZones.REAR_BUMPER}`]: "Pare-choc arrière",
  [`zone.${DamageZones.LEFT_REAR_HEADLIGHT}`]: "Feu arrière gauche",
  [`zone.${DamageZones.REAR_LICENSE_PLATE}`]: "Plaque d'immatriculation arrière",
  [`zone.${DamageZones.RIGHT_REAR_HEADLIGHT}`]: "Feu arrière droit",
  [`zone.${DamageZones.TRUNK}`]: "Coffre",
  [`zone.${DamageZones.REAR_WINDSHIELD}`]: "Pare-brise arrière",
  [`zone.${DamageZones.ROOF}`]: "Toit",
  [`zone.${DamageZones.FRONT_WINDSHIELD}`]: "Pare-brise avant",
  [`zone.${DamageZones.HOOD}`]: "Capot",
  [`zone.${DamageZones.LEFT_FRONT_HEADLIGHT}`]: "Feu avant gauche",
  [`zone.${DamageZones.GRILL}`]: "Grille",
  [`zone.${DamageZones.FRONT_RIGHT_HEADLIGHT}`]: "Feu avant droit",
  [`zone.${DamageZones.LEFT_FRONT_BUMPER}`]: "Bouclier avant gauche",
  [`zone.${DamageZones.FRONT_BUMPER}`]: "Pare-choc avant",
  [`zone.${DamageZones.RIGHT_FRONT_BUMPER}`]: "Bouclier avant droit",
  [`zone.${DamageZones.RIGHT_REAR_VIEW_MIRROR}`]: "Rétroviseur droit",
  [`zone.${DamageZones.SPARE_WHEEL}`]: "Roue de secours",
  [`zone.${DamageZones.RIGHT_REAR_FENDER}`]: "Aile arrière droite",
  [`zone.${DamageZones.RIGHT_REAR_WHEEL}`]: "Roue arrière droite",
  [`zone.${DamageZones.RIGHT_REAR_DOOR}`]: "Portière arrière droite",
  [`zone.${DamageZones.RIGHT_REAR_WINDOW}`]: "Vitre arrière droite",
  [`zone.${DamageZones.FRONT_RIGHT_WINDOW}`]: "Vitre avant droite",
  [`zone.${DamageZones.RIGHT_FRONT_DOOR}`]: "Portière avant droite",
  [`zone.${DamageZones.RIGHT_ROCKER_PANEL}`]: "Bas de caisse droit",
  [`zone.${DamageZones.RIGHT_FRONT_FENDER}`]: "Aile avant droite",
  [`zone.${DamageZones.RIGHT_FRONT_WHEEL}`]: "Roue avant droite",
  [`zone.${DamageZones.UNDEFINED}`]: "Non Définie",
  [`zone.${DamageZones.INSIDE}`]: "Intérieur",
  [`zone.${DamageZones.RIGHT_REAR_BUMPER}`]: "Pare-choc/bouclier arrière droit",
  [`zone.${DamageZones.LEFT_REAR_BUMPER}`]: "Pare-choc/bouclier arrière gauche",
  "restitution.buyer": "Repreneur",
  "restitution.buyerAddress": "Adresse du repreneur",
  "restitution.brand": "Marque",
  "restitution.chassis": "Numéro de châssis",
  "restitution.color": "Couleur extérieur",
  "restitution.contractId": "Contrat",
  "restitution.contractType": "Type de contrat",
  "restitution.contractType.L10": "DLA Reprise réseau",
  "restitution.contractType.L20": "DLO reprise constructeur",
  "restitution.contractType.L2R": "Reprise Réseau avec expertise Dekra",
  "restitution.contractType.LDER": "LDER",
  "restitution.contractType.LPER": "LPER",
  "restitution.contractType.light": "Type",
  "restitution.expert": "Expertise",
  [`restitution.receptionType.${RECEPTION_TYPE.RECEPTION}`]: "Réceptionnaire",
  [`restitution.receptionType.${RECEPTION_TYPE.EXPERTISE}`]: "Expert",
  "restitution.generalCondition": "État général",
  [`restitution.generalCondition.${GENERAL_CONDITION.CONFORME}`]: "Conforme",
  [`restitution.generalCondition.${GENERAL_CONDITION.DAMAGED}`]: "Endommagé",
  [`restitution.generalCondition.${GENERAL_CONDITION.UNKNOWN}`]: "Inconnu",
  [`restitution.generalCondition.${GENERAL_CONDITION.HAIL}`]: "Grêlé",
  "restitution.fuel": "Carburant",
  "restitution.firstRegistrationDate": "Date de 1er MEC",
  "restitution.licensePlate.light": "Immat.",
  "restitution.licensePlate": "Immatriculation",
  "restitution.mileage": "Kilométrage",
  "restitution.model": "Modèle",
  "restitution.nbPlaces": "Nombre de places",
  "restitution.observations": "Observations",
  "restitution.receptionDate": "Date de la restitution",
  "restitution.restitutionDate": "Date de restitution",
  "restitution.signatory": "Signataire",
  "restitution.noSignatory": "Signataire non présent",
  "restitution.signatoryRefused": "Refus de signature :",
  "restitution.validationDate": "Date de restitution",
  "restitution.combinedDate": "Date de restitution",
  "restitution.receptionPlace": "Lieu de la restitution",
  "restitution.receptionPlaceAddress": "Adresse de la restitution",
  "restitution.storagePlace": "Lieu de stockage",
  "restitution.status": "Statut",
  "restitution.tenant.name": "Nom du locataire",
  "restitution.tenant.address": "Adresse",
  "restitution.tenant.siret": "SIRET",
  "restitution.tenant": "Locataire",
  "restitution.totalPrice": "Montant après vétusté",
  "restitution.type": "Genre",
  "restitution.tireSeason": "Saison des pneus",
  "restitution.tireBrand": "Marque des pneus",
  "restitution.tireSize": "Taille des pneus",
  [`restitution.tireSeason.${TIRE_SEASON.WINTER}`]: "Hiver",
  [`restitution.tireSeason.${TIRE_SEASON.SUMMER}`]: "Été",
  [`restitution.tireSeason.${TIRE_SEASON.ALL_SEASON}`]: "Toutes saisons",
  "restitution.version": "Version",
  "restitution.profile.user.forgotten": "Utilisateur anonymisé",
  "restitution.user.forgotten": "Utilisateur anonymisé",
  "search.filter.date.min": "Date de début",
  "search.filter.date.max": "Date de fin",
  "error.default": "Une erreur est survenue",
  "error.recaptcha": "Erreur du service reCAPTCHA, merci de réessayer !",
  "error.password_not_match_rule": "Le mot de passe ne respecte pas la règle !",
  "error.password_not_match": "Les mots de passe sont différents !",
  "error.page": "La page est intouvable !",
  "common.month": "mois",
}

export default common
