import React, { useEffect, useRef, useState } from "react"

import { Home, House, Person, Phone } from "@mui/icons-material"
import { Box, Button, Dialog, Grid, Grow, Tooltip, Typography } from "@mui/material"
import { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import api from "api/api"
import { fonts } from "assets/fonts/fonts"
import { COLORS } from "assets/styles/colors"
import InfoBlock from "components/infoBlock"
import { AbsoluteLoader } from "components/loader"
import SplitButton from "components/splitButton/SplitButton";
import StatusLogo from "components/statusLogo"
import Expertise from "components/svg/Expertise"
import ExpertiseVU from "components/svg/ExpertiseVU"
import { SubTitle, Title } from "components/typography/Title"
import { getObservationIcon } from "screens/search/SearchTable"
import { RootState } from "store"
import { Restitution } from "types/common.types"
import {
  DOCUMENT_STATUS,
  DOWNLOADABLE_DOCUMENT,
  EXPERTS,
  OBSERVATION,
  RESTITUTION_STATUS,
  USER_TYPE,
  VEHICLE_TYPE
} from "types/enums.types"
import { getErrorMessages } from "utils/error.utils"
import {
  formatDateTime,
  formatMileage,
  formatNull,
  formatPrice,
  getNoSignatoryKey,
  getNoSignatoryWarning
} from "utils/search.utils"

import DamagesTable from "./DamagesTable"
import { restitutionKeys, settings, vehicleKeys } from "./Details.setting"
import * as S from "./Details.styles"
import MechanicalDamagesTable from "./MechanicalDamagesTable"

const amoviblesKeys: Array<keyof Restitution> = [
  "registrationCard",
  "duplicateKeys",
  "userManual",
  "domesticChargingCable",
  "fastChargingCable",
  "tools",
  "airCompressor",
  "antenna",
  "luggageCover",
]

type Amovible = Record<DOCUMENT_STATUS, string[]>

function Details() {
  const minHeight = useRef(10000)
  const [loading, setLoading] = useState<boolean>(true)
  const [restitution, setRestitution] = useState<Restitution>()
  const [amovibles, setAmovibles] = useState<Amovible | undefined>(undefined)
  const [modal, setModal] = useState(false)
  const [photos, setPhotos] = useState<string[]>([])
  const [photosLoaded, setPhotosLoaded] = useState<number>(0)
  const [photoErrors, setPhotoErrors] = useState<Record<string, boolean>>({})
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const intl = useIntl()
  const params = useParams()
  const user = useSelector((state: RootState) => state.auth.user)

  const { id } = params

  const noPhotos = Object.values(photoErrors).reduce((acc, cur) => (cur ? acc + 1 : acc), 0) === restitution?.photos?.length
  const restitutionLoadedPhotos = restitution?.photos?.filter((p) => !photoErrors[p])

  const onClickPreClose = async () => {
    try {
      setLoading(true)
      await api.search.preClose(id ?? "")
      await init()
    } catch (e) {
      const messages = getErrorMessages(e as AxiosError)
      messages.forEach((m) => enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  const init = async () => {
    try {
      setLoading(true)
      const { data } = await api.search.get(id || "")

      const a: Amovible = {
        [DOCUMENT_STATUS.PRESENT]: [],
        [DOCUMENT_STATUS.NOT_DELIVERED]: [],
        [DOCUMENT_STATUS.ABSENT]: [],
      }

      amoviblesKeys.forEach((k) => {
        if (data[k] === DOCUMENT_STATUS.PRESENT) {
          a[DOCUMENT_STATUS.PRESENT].push(k)
        }
        if (data[k] === DOCUMENT_STATUS.ABSENT) {
          a[DOCUMENT_STATUS.ABSENT].push(k)
        }
        if (data[k] === DOCUMENT_STATUS.NOT_DELIVERED) {
          a[DOCUMENT_STATUS.NOT_DELIVERED].push(k)
        }
      })

      setAmovibles(a)
      setRestitution(data)
    } catch (e) {
      const messages = getErrorMessages(e as AxiosError)
      messages.forEach((m) => enqueueSnackbar(intl.formatMessage({ id: m }), { variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const { tenant, receptionist, damages } = restitution || {}

  const onClickPhoto = (photos: string[]) => {
    setPhotos(photos)
    setModal(true)
  }

  const loadImage = (imageUrl: string) => {
    const img = new Image()
    img.src = imageUrl

    img.onload = () => {
      setPhotosLoaded((prev) => prev + 1)
    }

    img.onerror = () => {
      setPhotosLoaded((prev) => prev + 1)
      setPhotoErrors((prev) => ({ ...prev, [imageUrl]: true }))
    }
  }

  const hasDocuments: boolean = restitution?.receiptLink !== null || restitution?.pvLink !== null

  const documentsOptions: string[] = []

  if (restitution?.receiptLink) {
    documentsOptions.push(DOWNLOADABLE_DOCUMENT.RECEIPT_LINK)
  }

  if (restitution?.pvLink) {
    documentsOptions.push(DOWNLOADABLE_DOCUMENT.PV_LINK)
  }

  const buttonClick = (value: string) => {
    switch (value) {
      case DOWNLOADABLE_DOCUMENT.PV_LINK:
        window.open(restitution?.pvLink, '_blank');
        break
      case DOWNLOADABLE_DOCUMENT.RECEIPT_LINK:
        window.open(restitution?.receiptLink, '_blank');
        break
      default:
        return
    }
  }

  useEffect(() => {
    if (restitution) {
      restitution?.photos?.forEach((p) => loadImage(p))
    }
  }, [restitution])

  const getTooltip = () => {
    if (restitution) {
      if (restitution.status === RESTITUTION_STATUS.ERROR && restitution?.errorMessage) {
        return restitution.errorMessage
      } else {
        return intl.formatMessage({ id: `filter.status.${restitution.status}` })
      }
    }
    return ""
  }

  return (
    <Box pl={2} pr={2} pb={2} margin="auto" minHeight="100vh" display="flex" flexDirection="column">
      <Dialog onClose={() => setModal(false)} open={modal} PaperComponent={S.Container}>
        <Slider {...settings}>
          {photos &&
            photos.map((p, i) => (
              <S.ModalContainer key={p + i}>
                <S.ModalPhoto src={p} />
              </S.ModalContainer>
            ))}
        </Slider>
      </Dialog>
      <Box pt={2} pb={2}>
        <Button color="secondary" variant="outlined" onClick={() => navigate("/")}>
          <FormattedMessage id="button.back" />
        </Button>
      </Box>
      {!restitution && !loading && (
        <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
          <Title>
            <FormattedMessage id="error.page" />
          </Title>
        </Box>
      )}
      {loading && <AbsoluteLoader />}
      {restitution && (
        <Grid container spacing={2}>
          <Grow in>
            <Grid item container sm={12} xs={12} md={12} lg={9}>
              <Grid height="fit-content" container>
                <S.Card item container xs={12}>
                  <Grid item container xs={12} pb={1} justifyContent="space-between" position="relative">
                    <Title>{`${restitution.brand} ${restitution.model} ${restitution.version}`}</Title>
                    <Tooltip title={getTooltip()}>
                      <S.IconContainer>
                        <StatusLogo size={50} status={restitution.status} />
                      </S.IconContainer>
                    </Tooltip>
                  </Grid>
                  <Grid item container xs={11} justifyContent="space-between">
                    <InfoBlock
                      bold
                      title="restitution.contractType"
                      content={intl.formatMessage({ id: `restitution.contractType.${restitution.contractType}` })}
                    />
                    <InfoBlock bold title="restitution.licensePlate" content={restitution.licensePlate} />
                    <InfoBlock bold title="restitution.receptionPlace" content={formatNull(restitution.receptionPlace)} />
                    <InfoBlock
                      bold
                      title="restitution.combinedDate"
                      content={formatDateTime(intl, restitution.combinedDate ?? restitution.agendaDate)}
                    />
                    <InfoBlock bold title="restitution.mileage" content={formatMileage(intl, restitution.mileage)} />
                  </Grid>
                </S.Card>
                {restitution.expertComment && (
                  <S.Card item container xs={12}>
                    <Box>
                      <Box>
                        <Title>
                          <FormattedMessage id="restitution.expertComment" />
                        </Title>
                      </Box>
                      <Box display="flex" alignItems="center" pt={1}>
                        {restitution.expertComment}
                      </Box>
                    </Box>
                  </S.Card>
                )}
                <S.Card item container xs={12}>
                  <Grid item container xs={6}>
                    <Grid item container xs={12}>
                      <Title>
                        <FormattedMessage id="screen.details.vehicle" />
                      </Title>
                    </Grid>
                    {vehicleKeys.map((k) => (
                      <Grid key={k.name} item container xs={6} pt={1} pb={1}>
                        <InfoBlock
                          title={`restitution.${k.name}`}
                          content={k.format ? k.format(intl, restitution[k.name]) : formatNull(restitution[k.name])}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item container xs={6} height="fit-content">
                    <Grid item container xs={12}>
                      <Title>
                        <FormattedMessage id="screen.details.restitution" />
                      </Title>
                    </Grid>
                    {restitutionKeys.map((k) => (
                      <Grid key={k.name} item container xs={6} pt={1} pb={1}>
                        <InfoBlock
                          title={`restitution.${k.name}`}
                          content={k.format ? k.format(intl, restitution[k.name]) : formatNull(restitution[k.name])}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </S.Card>
                <Grid item container xs={12} style={{ backgroundColor: COLORS.GREY_BG }}>
                  <Grid item container xs={12} pl={3} pr={3} pt={2} pb={2}>
                    <Grid item container xs={12} pb={1}>
                      <Title>
                        <FormattedMessage id="screen.details.damages" values={{ price: formatPrice(intl, restitution.totalDamages) }} />
                      </Title>
                    </Grid>
                    <Grid item container md={7} xs={12} height="fit-content" justifyContent="space-between">
                      <Grid item container xs={6} pt={1} pb={1}>
                        <InfoBlock
                          title={"restitution.generalCondition"}
                          content={
                            restitution.generalCondition
                              ? intl.formatMessage({ id: `restitution.generalCondition.${restitution.generalCondition}` })
                              : "-"
                          }
                        />
                      </Grid>
                      <Grid item container xs={6} pt={1} pb={1}>
                        <InfoBlock title={"restitution.mileage"} content={formatMileage(intl, restitution.mileage)} />
                      </Grid>
                      <Grid item container xs={6} pt={1} pb={1}>
                        <InfoBlock
                          title={"restitution.cleanlinessInt"}
                          content={
                            restitution.cleanlinessInt
                              ? intl.formatMessage({ id: `restitution.cleanliness.${restitution.cleanlinessInt}` })
                              : "-"
                          }
                        />
                      </Grid>
                      <Grid item container xs={6} pt={1} pb={1}>
                        <InfoBlock
                          title={"restitution.cleanlinessExt"}
                          content={
                            restitution.cleanlinessExt
                              ? intl.formatMessage({ id: `restitution.cleanliness.${restitution.cleanlinessExt}` })
                              : "-"
                          }
                        />
                      </Grid>
                      <Grid item container xs={6} pt={1} pb={1}>
                        <InfoBlock
                          title={"restitution.tireSeason"}
                          content={
                            restitution.tireSeason ? intl.formatMessage({ id: `restitution.tireSeason.${restitution.tireSeason}` }) : "-"
                          }
                        />
                      </Grid>
                      <Grid item container xs={6} pt={1} pb={1}>
                        <InfoBlock title={"restitution.tireBrand"} content={restitution.tireBrand ?? "-"} />
                      </Grid>
                      <Grid item container xs={6} pt={1} pb={1}>
                        <InfoBlock title={"restitution.tireSize"} content={restitution.tireSize ? `${restitution.tireSize}"` : "-"} />
                      </Grid>
                    </Grid>
                    <Grid item container md={5} xs={12} height="fit-content" justifyContent="space-between" pl={4} pr={4} spacing={1}>
                      {restitution.schemaType === VEHICLE_TYPE.VU ? (
                        <ExpertiseVU damages={restitution.damages || []} />
                      ) : (
                        <Expertise damages={restitution.damages || []} />
                      )}
                    </Grid>
                    {amovibles && (
                      <Grid item container xs={12} pb={1}>
                        <Title>
                          <FormattedMessage id="screen.details.amovibles" />
                        </Title>
                        <Grid item container xs={12} spacing={1} pt={1}>
                          {Object.keys(amovibles).map((k) => (
                            <Grid key={k} item container xs={12 / Object.keys(amovibles).length}>
                              <Grid item container xs={12} sx={{ backgroundColor: "white" }} p={1}>
                                <Grid item container xs={12} height="fit-content">
                                  <Grid item xs={12}>
                                    <SubTitle>
                                      <FormattedMessage id={`screen.details.amovibles.${k}`} />
                                    </SubTitle>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {amovibles[k as DOCUMENT_STATUS].map((v: string) => (
                                      <div key={v}>{`- ${intl.formatMessage({ id: `restitution.${v}` })}`}</div>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {damages && damages.length > 0 && (
                    <Grid item container xs={12} height="fit-content" pl={2} pr={2} pb={2}>
                      <DamagesTable damages={damages} restitution={restitution} onClickPhoto={onClickPhoto} />
                    </Grid>
                  )}
                  {restitution?.mechanicalDamages && (
                    <Grid item container xs={12} pl={3} pr={3} pt={2} pb={2}>
                      <Grid item container xs={12} pb={1}>
                        <Title>
                          <FormattedMessage id="screen.details.mechanicalDamages" />
                        </Title>
                        <MechanicalDamagesTable mechanicalDamages={restitution.mechanicalDamages} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grow>
          <Grid item container xs={12} sm={12} md={12} lg={3} height="fit-content">
            {!noPhotos && photosLoaded >= restitution?.photos?.length && (
              <Grid item xs={12} mb={1} height="fit-content" sx={{
                backgroundColor: COLORS.GREY_BG,
                maxHeight: minHeight.current
              }}>
                <Slider {...settings} draggable={false}>
                  {restitutionLoadedPhotos &&
                    restitutionLoadedPhotos.map((p) => (
                      <div key={p}>
                        <S.PhotoContainer style={{ height: minHeight.current }}>
                          <S.Photo
                            src={p}
                            alt="vehicle"
                            onClick={() => {
                              onClickPhoto(restitutionLoadedPhotos)
                            }}
                            onLoad={(e: any) => {
                              if (e?.target?.offsetHeight < minHeight.current) {
                                minHeight.current = e?.target?.offsetHeight
                                // trigger re-render to update size
                                setPhotosLoaded((prev) => prev + 1)
                              }
                            }}
                            style={{ maxHeight: minHeight.current }}
                          />
                        </S.PhotoContainer>
                      </div>
                    ))}
                </Slider>
              </Grid>
            )}

            {hasDocuments && documentsOptions.length > 1 &&
              <Grid xs={12} mb={1} display="flex" justifyContent="flex-end">
                <SplitButton options={documentsOptions} onClick={buttonClick} intlKey={"screen.details"} text={intl.formatMessage({ id: 'button.download' })} />
              </Grid>
            }

            {hasDocuments && documentsOptions.length === 1 &&
              <Grow in>
                <Grid pt={0} pb={1} item container xs={12} justifyContent="flex-end">
                  <Grid item xs={12} xl={8}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => buttonClick(documentsOptions[0])}
                    >
                      {intl.formatMessage({ id: `screen.details.${documentsOptions[0]}` })}
                    </Button>
                  </Grid>
                </Grid>
              </Grow>
            }
            {(restitution?.status === RESTITUTION_STATUS.VALIDATED || restitution?.status === RESTITUTION_STATUS.ERROR) &&
              user?.type === USER_TYPE.ADMIN && (
                <Grow in>
                  <Grid pt={0} pb={1} item container xs={12} justifyContent="flex-end">
                    <Grid item xs={12} xl={8}>
                      <Button variant="contained" fullWidth onClick={onClickPreClose}>
                        {intl.formatMessage({ id: "screen.details.preClose" })}
                      </Button>
                    </Grid>
                  </Grid>
                </Grow>
              )}

            <Grow in>
              <S.Card item container xs={12}>
                <Box>
                  <Box>
                    <Title>
                      <FormattedMessage id="restitution.observations" />
                    </Title>
                  </Box>
                  <Box display="flex" alignItems="center" pt={1}>
                    <S.Icon>{getObservationIcon(OBSERVATION.DUPLICATE_KEYS, restitution.duplicateKeys)}</S.Icon>
                    <FormattedMessage id={`tooltip.duplicateKeys.${restitution.duplicateKeys}`} />
                  </Box>
                  <Box display="flex" alignItems="center" pt={1}>
                    <S.Icon>{getObservationIcon(OBSERVATION.REGISTRATION_CARD, restitution.registrationCard)}</S.Icon>
                    <FormattedMessage id={`tooltip.registrationCard.${restitution.registrationCard}`} />
                  </Box>
                  <Box display="flex" alignItems="center" pt={1}>
                    <S.Icon>
                      {getObservationIcon(
                        OBSERVATION.NO_SIGNATORY,
                        getNoSignatoryWarning(restitution.noSignatory, restitution.signatoryRefused)
                      )}
                    </S.Icon>
                    <FormattedMessage id={getNoSignatoryKey(restitution.noSignatory, restitution.signatoryRefused)} />
                  </Box>
                  <Box display="flex" alignItems="center" pt={1} pb={1}>
                    <S.Icon>{getObservationIcon(OBSERVATION.NOT_MOVING, restitution.notMoving)}</S.Icon>
                    <FormattedMessage id={`tooltip.notMoving.${restitution.notMoving}`} />
                  </Box>
                  {restitution.massRetail && (
                    <Box display="flex" alignItems="center" pt={1} pb={1}>
                      <S.Icon>{getObservationIcon(OBSERVATION.MASS_RETAIL, restitution.massRetail)}</S.Icon>
                      <FormattedMessage id="tooltip.massRetail" />
                    </Box>
                  )}
                </Box>
              </S.Card>
            </Grow>
            <Grow in>
              <S.Card item container xs={12}>
                <Box>
                  <Box>
                    <Title>
                      <FormattedMessage id="restitution.tenant" />
                    </Title>
                  </Box>
                  <Box display="flex" alignItems="center" pt={1}>
                    <S.Icon>
                      <Person fontSize="inherit" />
                    </S.Icon>
                    {tenant && formatNull(tenant.lastName)}
                  </Box>
                  <Box display="flex" alignItems="center" pt={1}>
                    <S.Icon>
                      <Home fontSize="inherit" />
                    </S.Icon>
                    {tenant && formatNull(tenant.address)}
                  </Box>
                  <Box display="flex" alignItems="center" pt={1} pb={1}>
                    <S.Icon>
                      <Phone fontSize="inherit" />
                    </S.Icon>
                    {tenant && formatNull(tenant.phone)}
                  </Box>
                </Box>
              </S.Card>
            </Grow>
            <Grow in>
              <S.Card item container xs={12}>
                <Box>
                  <Box>
                    <Title>
                      <FormattedMessage id="restitution.signatory" />
                    </Title>
                  </Box>
                  {!restitution?.noSignatory && restitution?.signatory && (
                    <Box display="flex" alignItems="center" pt={1}>
                      <S.Icon>
                        <Person fontSize="inherit" />
                      </S.Icon>
                      {formatNull(restitution?.signatory?.firstName) + " " + formatNull(restitution?.signatory?.lastName)}
                    </Box>
                  )}
                  {restitution?.noSignatory && (
                    <Box display="flex" alignItems="center" pt={1}>
                      <FormattedMessage id="restitution.noSignatory" />
                    </Box>
                  )}
                  {restitution?.signatoryRefused && (
                    <>
                      <Box display="flex" alignItems="center" pt={2}>
                        <Typography fontWeight={800}>
                          <FormattedMessage id="restitution.signatoryRefused" />
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        {restitution?.signatoryComment}
                      </Box>
                    </>
                  )}
                </Box>
              </S.Card>
            </Grow>
            <Grow in>
              <S.Card item container xs={12}>
                <Box>
                  <Box>
                    <Title>
                      <FormattedMessage id={`restitution.receptionType.${restitution.receptionType}`} />
                    </Title>
                  </Box>
                  <Box display="flex" alignItems="center" pt={1}>
                    <S.Icon>
                      <Box width={26} fontSize={12} display="flex" justifyContent="flex-end">
                        {getObservationIcon(OBSERVATION.RECEPTIONIST, receptionist?.company?.toUpperCase() === EXPERTS.DEKRA)}
                      </Box>
                    </S.Icon>
                    {formatNull(receptionist?.company?.toUpperCase())}
                  </Box>
                  <Box display="flex" alignItems="center" pt={1} sx={{ fontFamily: fonts.bold }}>
                    <S.Icon>
                      <Person fontSize="inherit" />
                    </S.Icon>
                    {receptionist?.forgotten
                      ? intl.formatMessage({ id: `restitution.user.forgotten` })
                      : formatNull(receptionist?.firstName) + " " + formatNull(receptionist?.lastName)}
                  </Box>
                  <Box display="flex" alignItems="center" pt={1}>
                    <S.Icon>
                      <House fontSize="inherit" />
                    </S.Icon>
                    {formatNull(receptionist?.office)}
                  </Box>
                </Box>
              </S.Card>
            </Grow>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default Details
